import classNames from 'classnames';
import Select from 'react-select';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import ErrorMessage from '@/components/integrated/error-message';
import NextImage from '@/components/data-display/image';
import { CustomComponents } from './input-select.utils';
import type { InputSelectProperties, OptionsType } from './input-select.types';
import styles from './input-select.module.scss';

const InputSelect = ({
  id,
  name,
  defaultValue,
  value,
  onChange,
  label,
  ariaLabel,
  className,
  errorMessage,
  hasError,
  options,
  placeholder,
  dataId,
  isSearchable = false,
  isDisabled,
  invertColors,
  isLoading = false,
}: InputSelectProperties) => {
  const formatOptionLabel = ({ label: labelOption, image }: OptionsType) => {
    if (!image) {
      return (
        <div className={styles['input-select__option-container']}>
          {labelOption}
        </div>
      );
    }
    return (
      <div className={styles['input-select__option-container']}>
        <div className={styles['input-select__option-image']}>
          <NextImage src={image} alt="icon" fill isLocal />
        </div>
        {labelOption}
      </div>
    );
  };

  return (
    <div className={classNames(styles['input-select'], className)}>
      {label && (
        <label
          htmlFor={id}
          className={classNames(styles['input-select__label'], {
            [styles['input-select__label--inverted']]: invertColors,
          })}
        >
          {label}
        </label>
      )}
      <div
        className={classNames(
          styles['input-select__wrapper'],
          {
            [styles['input-select__wrapper--error']]: hasError,
          },
          {
            [styles['input-select__wrapper--disabled']]: isDisabled,
          },
          {
            [styles['input-select__wrapper--inverted']]: invertColors,
          },
        )}
      >
        {isSearchable && (
          <Icon
            className={styles['input-select__icon']}
            variant={ICON_VARIANT.Search}
          />
        )}

        <Select
          id={id}
          name={name}
          isDisabled={isDisabled}
          options={options}
          defaultValue={options?.find(option => option.value === defaultValue)}
          value={value ?? null}
          isOptionSelected={() => value !== undefined}
          onChange={onChange}
          placeholder={placeholder}
          formatOptionLabel={formatOptionLabel}
          classNames={{
            container: () => styles['input-select__container'],
            control: () => styles['input-select__control'],
            placeholder: () => {
              return invertColors
                ? styles['input-select__placeholder--inverted']
                : styles['input-select__placeholder'];
            },
            option: ({ isFocused }) => {
              if (isSearchable) {
                return isFocused
                  ? classNames(
                      styles['input-select__option-focused-searchable'],
                      {
                        [styles[
                          'input-select__option-focused-searchable--inverted'
                        ]]: invertColors,
                      },
                    )
                  : styles['input-select__option-searchable'];
              }
              return isFocused
                ? classNames(styles['input-select__option-focused'], {
                    [styles['input-select__option-focused--inverted']]:
                      invertColors,
                  })
                : styles['input-select__option'];
            },
            indicatorSeparator: () =>
              invertColors
                ? styles['input-select__indicator-separator--inverted']
                : styles['input-select__indicator-separator'],
          }}
          unstyled
          isSearchable={isSearchable}
          components={CustomComponents({
            invertColors,
            isSearchable,
          })}
          data-id={dataId}
          aria-label={ariaLabel}
          aria-labelledby={id}
          isLoading={isLoading}
        />
      </div>
      {hasError && errorMessage && (
        <ErrorMessage
          errorMessage={errorMessage}
          showIcon
          className={styles['input-select__error']}
        />
      )}
    </div>
  );
};

export default InputSelect;
