import { TagGroup, Tag } from '@alto-avios/alto-ui';
import type { SelectTagListProperties } from './select-tag-list.types';

const SelectTagList = ({
  items = [],
  handleAction = () => {},
  selectedCategories = [],
}: SelectTagListProperties) => {
  return (
    <TagGroup
      selectedKeys={selectedCategories}
      selectionMode="multiple"
      clearButton
      clearButtonOnClick={() => handleAction([])}
      onSelectionChange={keys => handleAction([...keys] as string[])}
    >
      {items.map(item => (
        <Tag id={item.slug} key={item.slug}>
          {item.label}
        </Tag>
      ))}
    </TagGroup>
  );
};

export default SelectTagList;
