import classNames from 'classnames';
import { ComponentType } from 'react';
import {
  MenuProps,
  components,
  SelectComponentsConfig,
  GroupBase,
} from 'react-select';
import styles from './input-select.module.scss';
import { CustomComponentsProperties, OptionsType } from './input-select.types';

export const CustomComponents = ({
  invertColors,
  isSearchable,
}: CustomComponentsProperties): SelectComponentsConfig<
  OptionsType,
  boolean,
  GroupBase<OptionsType>
> => {
  const Menu: ComponentType<
    MenuProps<OptionsType, boolean, GroupBase<OptionsType>>
  > = ({ children, ...properties }) => (
    <div className={styles['input-select__menu']}>
      <components.Menu
        {...properties}
        className={classNames(styles['input-select__menu-items'], {
          [styles['input-select__menu-items--inverted']]: invertColors,
        })}
      >
        {children}
      </components.Menu>
    </div>
  );

  return {
    Menu,
    ...(isSearchable && { DropdownIndicator: null }),
  };
};
