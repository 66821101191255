import classNames from 'classnames';
import Button from '@/components/inputs/button';
import {
  SIZE,
  VARIANT as ButtonVariant,
} from '@/components/inputs/button/button.types';
import { VARIANT as IconVariant } from '@/components/data-display/icon/icon.types';
import { DATA_IDS } from '@/types';
import type { FilterListProperties } from './filter-list.types';
import styles from './filter-list.module.scss';

const FilterList = ({
  items,
  onClickReset,
  resetLabel,
  className = '',
}: FilterListProperties) => {
  if (items && items.length > 0) {
    let showReset = false;

    const list = items.map(item => {
      if (item.selected) {
        showReset = true;
      }
      return (
        <Button
          key={item.label}
          onClick={item.handleItemClick}
          variant={ButtonVariant.Tertiary}
          size={SIZE.Small}
          className={classNames(styles['filter-list__filter-item'], {
            [styles['filter-list__filter-item--selected']]: item.selected,
          })}
          title={item.label}
          data-id={DATA_IDS.FILTER_LIST_PARTNER_CATEGORY}
        >
          {item.label}
        </Button>
      );
    });

    return (
      <div
        role="region"
        className={classNames(styles['filter-list'], className)}
      >
        {list}
        {resetLabel && showReset && (
          <Button
            onClick={onClickReset}
            variant={ButtonVariant.TextBlock}
            size={SIZE.Small}
            leadingIcon={IconVariant.X}
            className={styles['filter-list__reset']}
            title={resetLabel}
            data-id={DATA_IDS.RESET_FILTERS}
          >
            {resetLabel}
          </Button>
        )}
      </div>
    );
  }
  return null;
};

export default FilterList;
