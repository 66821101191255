import useSWRInfinite from 'swr/infinite';
import { ParsedUrlQuery } from 'node:querystring';
import { COLLECTION_MECHANIC } from '../../models/collection-mechanic/collection-mechanic.types';
import fetcher from '../../utils/fetcher';
import { TILE_TYPE } from '../../modules/partner/partner-list/partner-list.types';
import { generatePartnerQueryUrl } from './use-get-partners.utils';

const useGetPartners = (
  query: ParsedUrlQuery | string,
  collectionMechanic: COLLECTION_MECHANIC[] | [],
  higherRate?: {
    showHighestRate: boolean | undefined;
    rateUpTo: string | undefined;
    fromText: string | undefined;
    wasRateText: string | undefined;
  },
  partnerType?: TILE_TYPE,
  isSpecialOffers?: boolean,
) => {
  const {
    data: response,
    isLoading,
    size,
    setSize,
    isValidating,
  } = useSWRInfinite(
    index => {
      const BASE_URL = '/api/partners';

      const partnerQueryParameters = {
        page: index,
        isSpecialOffers,
        ...(query as ParsedUrlQuery),
        ...higherRate,
        ...(partnerType === TILE_TYPE.Hero && { partnerData: partnerType }),
        ...(collectionMechanic.length > 0 && {
          collectionMechanic: collectionMechanic.join(','),
        }),
      };

      return generatePartnerQueryUrl(BASE_URL, partnerQueryParameters);
    },
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const pagination = response?.[0]?.pagination ?? {};

  const partnerList = response
    ?.map(object => object.data)
    ?.reduce((accumulator, value) => [...accumulator, ...value]);

  return {
    partnerList,
    pagination,
    isLoading,
    isValidating,
    size,
    setSize,
  };
};
export default useGetPartners;
