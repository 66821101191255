import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'node:querystring';
import { useState } from 'react';
import { COLLECTION_MECHANIC } from '@/models/collection-mechanic/collection-mechanic.types';
import Section from '@/components/integrated/section';
import FilterList from '@/components/integrated/filter-list';
import LoadMore from '@/components/navigation/load-more/load-more';
import Loader from '@/components/feedback/loader';
import InputSelect from '@/components/inputs/input-select';
import PartnerList from '@/modules/partner/partner-list';
import useCategoryToggle from '@/hooks/use-category-toggle';
import useGetPartners from '@/hooks/use-get-partners';
import trackEvent from '@/utils/track-event/track-event';
import { EVENT_NAME } from '@/utils/track-event/track-event.types';
import { DATA_IDS } from '@/types';
import { useFeatureTogglesContext } from '@/context/feature-toggles/feature-toggles-context';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import SelectTagList from '@/components/integrated/select-tag-list';
import { PARTNERS_SECTION_REFERENCE } from './partners-section.types';
import type { PartnersSectionProperties } from './partners-section.types';
import styles from './partners-section.module.scss';

const PartnersSection = ({
  header,
  filters,
  sorting,
  partners,
  loadMore,
  collectionMechanic = [],
  showHighestRate,
  clickLocation,
  tileType,
  listType,
  filteredPartnersCategories = [],
  reference,
  isSpecialOffers,
  sectionAriaLabel,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
PartnersSectionProperties) => {
  const router = useRouter();
  const featureToggles = useFeatureTogglesContext();
  const aviosAltoComponentsEnabled = featureToggles.includes(
    FEATURE_TOGGLE_LIST.TEMP_AVIOS_ALTO_COMPONENTS,
  );

  const isFilteredPartners = reference === PARTNERS_SECTION_REFERENCE.GPT;
  const showControls = !isFilteredPartners;

  const { categories = [] } = filters ?? {};
  const { allLabel } = header ?? {};
  const { sortByLabel = '', sortItems = [] } = sorting || {};
  const { wasRateText, rateUpTo, fromText, speedyAwardText } = partners || {};
  const { loadMoreLabel, ofLabel } = loadMore || {};

  const originalURLPath = router.pathname;
  const {
    sort: sortQueryString,
    c: categoriesQueryString = [],
    search,
  } = router.query;

  const [allCategorySelected, setAllCategorySelected] = useState<boolean>(
    categoriesQueryString.length === 0,
  );

  const locationMap: Record<string, string> = {
    '/retailers': 'collect online',
    '/collect-on-card': 'collect in-store',
    '/offers': 'special offers',
  };

  const locationName = locationMap[originalURLPath];

  const categoriesQueryArray =
    typeof categoriesQueryString === 'string'
      ? [categoriesQueryString]
      : categoriesQueryString;

  const routerQuery = isFilteredPartners
    ? {
        c: filteredPartnersCategories,
      }
    : router.query;

  const searchCollectionMechanic: [] | COLLECTION_MECHANIC[] = search
    ? []
    : collectionMechanic;

  const { partnerList, pagination, isLoading, size, setSize, isValidating } =
    useGetPartners(
      routerQuery,
      searchCollectionMechanic,
      {
        showHighestRate,
        rateUpTo,
        fromText,
        wasRateText,
      },
      tileType,
      isSpecialOffers,
    );

  const handleLoadMore = () => {
    trackEvent({
      event: EVENT_NAME.NAVIGATION,
      nav_element: `${locationName} pagination`,
    });
    setSize(size + 1);
  };

  const handleUrlUpdate = (inputQuery: ParsedUrlQuery) => {
    const { ...queryParameters } = router.query;

    const query = {
      ...queryParameters,
      ...inputQuery,
    };
    const pathname = router.asPath?.split('?')[0];
    router.replace(
      {
        query,
      },
      { pathname, query },
      { shallow: true },
    );
  };
  const handleCategoryChange = (selectedSlugs: string[]) => {
    setAllCategorySelected(false);
    trackEvent({
      event: EVENT_NAME.MERCHANT_FILTER,
      location: locationName,
      filters_applied: selectedSlugs.join(','),
    });
    handleUrlUpdate({ c: selectedSlugs });
  };

  const handleSortChange = (sort: string) => {
    trackEvent({
      event: EVENT_NAME.MERCHANT_SORT,
      location: locationName,
      sort_applied: sort,
    });
    handleUrlUpdate({ sort });
  };

  const [
    { mappedCategories = [], selectedCategories },
    { reset: handleCategoryReset },
    { handleAction },
  ] = useCategoryToggle({
    allCategories: categories,
    onChange: handleCategoryChange,
    initialValue: categoriesQueryArray,
  });

  const mappedSortItems = sortItems.map(sortItem => {
    if (sortQueryString) {
      return {
        ...sortItem,
        isActive: sortQueryString === sortItem.value,
      };
    }
    return sortItem;
  });

  if (isFilteredPartners && filteredPartnersCategories.length === 0)
    return null;

  // TODO: Remove extra code when TEMP_AVIOS_ALTO_COMPONENTS is removed
  const allCategory = {
    label: allLabel ?? '',
    selected: allCategorySelected,
    handleItemClick: () => {
      if (handleCategoryReset) handleCategoryReset();
      setAllCategorySelected(current => !current);
    },
    slug: 'all',
  };
  const filterItems = aviosAltoComponentsEnabled
    ? mappedCategories
    : [allCategory, ...mappedCategories];

  const sortByActiveOption = mappedSortItems.find(item => {
    return item.isActive;
  });

  return (
    <Section
      className={styles['partners-section']}
      ariaLabel={sectionAriaLabel}
      isFullBleed
    >
      {showControls && (
        <div className={styles['partners-section__filters']}>
          <div className={styles['partners-section__filters-container']}>
            <div className={styles['partners-section__filters-categories']}>
              {aviosAltoComponentsEnabled ? (
                <SelectTagList
                  items={filterItems}
                  className={styles['partners-section__filter-list']}
                  handleAction={handleAction}
                  selectedCategories={selectedCategories}
                />
              ) : (
                <FilterList
                  items={filterItems}
                  className={styles['partners-section__filter-list']}
                />
              )}
            </div>
            <div className={styles['partners-section__sort-wrapper']}>
              <InputSelect
                label={sortByLabel}
                className={styles['partners-section__sort-list']}
                value={sortByActiveOption}
                options={mappedSortItems}
                onChange={item => handleSortChange(item.value)}
                dataId={DATA_IDS.FILTER_LIST_SORT_SELECTOR}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles['partners-section__partner-list']}>
        {!isLoading && (
          <PartnerList
            listType={listType}
            tileType={tileType}
            partnerList={partnerList}
            clickLocation={clickLocation}
            speedyAwardText={speedyAwardText}
            className={styles['partners-section__partner-list-container']}
          />
        )}
      </div>

      {isLoading && <Loader className={styles['partners-section__loader']} />}

      {!isLoading && (
        <LoadMore
          loadMoreLabel={loadMoreLabel}
          ofLabel={ofLabel}
          currentItems={partnerList?.length}
          totalItems={pagination.total}
          onClick={handleLoadMore}
          isLoading={isValidating}
          className={styles['partners-more']}
        />
      )}
    </Section>
  );
};

export default PartnersSection;
