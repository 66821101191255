import { rest } from 'msw';
import { AVIOS_PREFIX } from '@/constants';
import getPartnersResultsResponse from './__fixtures__/get-partners-results.json';

const getPartnersResults = rest.get(
  `${AVIOS_PREFIX}/api/partners`,
  (_, response, context) => response(context.json(getPartnersResultsResponse)),
);

export default [getPartnersResults];
