import classNames from 'classnames';
import Button from '@/components/inputs/button';
import { SIZE, VARIANT } from '@/components/inputs/button/button.types';
import { VARIANT as IconVariant } from '@/components/data-display/icon/icon.types';
import { DATA_IDS } from '@/types';
import type { LoadMoreProperties } from './load-more.types';
import styles from './load-more.module.scss';

const LoadMore = ({
  currentItems,
  totalItems,
  onClick,
  isLoading = false,
  loadMoreLabel,
  ofLabel,
  className = '',
}: LoadMoreProperties) => {
  const percentage = currentItems / totalItems;

  if (totalItems > 0 && totalItems > currentItems) {
    return (
      <div
        role="region"
        className={classNames(styles['load-more'], className)}
        data-id={DATA_IDS.LOAD_MORE}
      >
        <Button
          trailingIcon={IconVariant.ArrowDown}
          variant={VARIANT.TextBlock}
          onClick={onClick}
          size={SIZE.Small}
          isLoading={isLoading}
          className={styles['load-more__button']}
          title={loadMoreLabel}
        >
          {loadMoreLabel}
        </Button>
        <div className={styles['load-more__bar']}>
          <div
            aria-label={`${percentage * 100}%`}
            role="progressbar"
            className={styles['load-more__bar-fill']}
            style={{ width: `${percentage * 100}%` }}
          />
        </div>
        <div className={styles['load-more__viewed']}>
          {currentItems} {ofLabel} {totalItems}
        </div>
      </div>
    );
  }

  return null;
};

export default LoadMore;
