import type { COLLECTION_MECHANIC } from '@/models/collection-mechanic/collection-mechanic.types';
import type { OptionsType } from '@/components/inputs/input-select/input-select.types';
import type { CLICK_LOCATION } from '@/utils/register-partner-click/register-partner-click.types';
import type { TILE_TYPE, LIST_TYPE } from '../partner-list/partner-list.types';

export type PartnersSectionProperties = {
  hasError?: boolean;
  header?: {
    allLabel: string;
  };
  filters?: {
    filterByLabel: string;
    clearFiltersLabel: string;
    categories: Array<{ name: string; slug: string }>;
  };
  sorting: {
    sortByLabel?: string;
    sortItems: OptionsType[];
  };
  partners: {
    wasRateText: string;
    rateUpTo: string;
    fromText: string;
    speedyAwardText?: string;
  };
  loadMore: {
    loadMoreLabel: string;
    ofLabel: string;
  };
  collectionMechanic?: COLLECTION_MECHANIC[] | [];
  showHighestRate?: boolean;
  clickLocation?: CLICK_LOCATION;
  tileType?: TILE_TYPE;
  listType?: LIST_TYPE;
  filteredPartnersCategories?: string[];
  reference?: PARTNERS_SECTION_REFERENCE;
  isSpecialOffers?: boolean;
  sectionAriaLabel?: string;
};

export enum PARTNERS_SECTION_REFERENCE {
  GPT = 'GPT',
}

export enum SORT_ITEMS {
  'merchantListFilterable.sort.lowestRate' = 'lowest-rate',
  'merchantListFilterable.sort.highestRate' = 'highest-rate',
  'merchantListFilterable.sort.mostPopular' = 'online-popularity',
  'merchantListFilterable.sort.name' = 'name',
}

type MicrocopyKeys =
  | 'merchantListFilterable.sort.lowestRate'
  | 'merchantListFilterable.sort.highestRate'
  | 'merchantListFilterable.sort.mostPopular'
  | 'merchantListFilterable.sort.name'
  | 'speedyAward.title'
  | 'loadMore.loadMore'
  | 'loadMore.of';

export type PartnersSectionContentfulResponse = {
  microcopyCollection: {
    items: Array<{
      key: MicrocopyKeys;
      value: string;
    }>;
  };
};

export type PartnersSectionCBRResponse = {
  category_locale: Array<{
    category: { slug: string };
    name: string;
  }>;
};
