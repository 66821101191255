import { useMemo, useState } from 'react';
import { UseCategoriesType } from './use-category-toggle.types';

const useCategoryToggle = ({
  allCategories,
  onChange = () => false,
  initialValue = [],
}: UseCategoriesType) => {
  const [selectedCategories, setSelectedCategories] =
    useState<string[]>(initialValue);

  // TODO: Remove extra code when TEMP_AVIOS_ALTO_COMPONENTS is removed
  // remove the code supporting the scenarios where slug is of type string or undefined
  return useMemo(() => {
    const handleAction = (slug?: string | string[]) => {
      if (
        slug &&
        typeof slug === 'string' &&
        selectedCategories.includes(slug)
      ) {
        const newSelectedCategories = selectedCategories.filter(
          category => slug !== category,
        );
        setSelectedCategories(newSelectedCategories);
        onChange(newSelectedCategories);
      } else if (slug === undefined) {
        setSelectedCategories([]);
        onChange([]);
      } else if (
        Array.isArray(slug) &&
        slug.length < selectedCategories.length
      ) {
        setSelectedCategories(slug);
        onChange(slug);
      } else {
        const slugs = typeof slug === 'string' ? [slug] : slug;
        const newSelectedCategories = [
          ...new Set([...selectedCategories, ...slugs]),
        ];
        setSelectedCategories(newSelectedCategories);
        onChange(newSelectedCategories);
      }
    };

    return [
      {
        mappedCategories: allCategories.map(({ name, slug }) => ({
          label: name.replace('&amp;', '&'),
          selected: selectedCategories.includes(slug),
          slug,
          handleItemClick: () => handleAction(slug),
        })),
        selectedCategories,
      },
      { reset: () => handleAction() },
      { handleAction },
    ];
  }, [onChange, selectedCategories, allCategories]);
};

export default useCategoryToggle;
